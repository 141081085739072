import React, { FC, ReactElement } from 'react';
import { graphql } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';
import SymptomIllustration from 'components/SymptomsIllustrations/SymptomIllustration';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IPropsSymptomsIllustrations } from './model';

import './SymptomsIllustrations.scss';

const SymptomsIllustrations: FC<IPropsSymptomsIllustrations> = ({
  headingGroup,
  illustrations,
  symptomsAnchor,
}): ReactElement => {
  const { isMobile } = useScreenRecognition({ isMobile: true });

  return (
    <div
      className="nf-symptom-illustrations"
      data-test="SymptomsIllustrations"
      {...(symptomsAnchor ? { id: symptomsAnchor } : {})}
    >
      <div className="nf-symptom-illustrations__caption-wrapper">
        <div className="nf-symptom-illustrations__caption">
          {headingGroup?.symptomsHeadingLg ? (
            <DangerouslySetInnerHtml
              className="nf-symptom-illustrations__main-title"
              html={headingGroup.symptomsHeadingLg}
            />
          ) : null}
          {headingGroup?.symptomsHeadingMd ? (
            <strong className="nf-symptom-illustrations__subtitle">
              {headingGroup.symptomsHeadingMd}
            </strong>
          ) : null}
          {!headingGroup?.showDescriptionOnMobile &&
          isMobile ? null : headingGroup?.symptomsDescription ? (
            <DangerouslySetInnerHtml
              className="nf-symptom-illustrations__text"
              html={headingGroup.symptomsDescription}
            />
          ) : null}
        </div>
      </div>
      <div className="nf-symptom-illustrations__illustrations-holder">
        {illustrations?.length &&
          illustrations.map(({ title, imageAlt, image, path, description }) => (
            <SymptomIllustration
              title={title}
              image={image}
              imageAlt={imageAlt}
              path={path}
              description={description}
            />
          ))}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSymptomsIllustrations on ISymptomsIllustrations {
    symptomsListItems {
      properties {
        symptomDataImage {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          svg {
            content
          }
        }
        symptomDataImageAlt
        symptomDataLink {
          name
          url
        }
        symptomTitle
        symptomDescription
      }
    }
    symptomsHeadingGroup {
      properties {
        showDescriptionOnMobile
        symptomsDescription
        symptomsHeadingLg
        symptomsHeadingMd
      }
    }
    symptomsAnchor
  }
`;

export default SymptomsIllustrations;
